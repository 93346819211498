<template>
    <van-popup 
        style="background:transparent" 
        get-container='body' 
        v-model='visiblePop' 
        round
        :position='isMobile ? "bottom" : "center"' 
        :close-on-click-overlay="false"
        @close='onSuccess()'
    >

        <div class='pop-order-cancel-container' :class="isMobile ? 'mobile' : 'pc'">

            <div class='topbar'>
                {{ $sys('mall.orderOneCancel.title') }}
                <van-icon color='#BDBDBD' name='cross' @click='onSuccess()' />
            </div>

            <div class='pop-order-cancel-content' ref='listRef'>

                <p class="dec">{{ $sys('mall.orderOneCancel.content') }}</p>

                <ul 
                    v-if='orderList.length > 0'
                    class='list' 
                    v-loading="loading"
                >
                    <li 
                        v-for='item in orderList' 
                        :class='["item", {"selected": item.isSelected}]'
                        :key='item.orderCode' 
                        @click='handleSelected(item)'
                    >
                        <p style='font-size: 15px;'><b>{{ item.commodityName }} </b></p>

                        <div style='font-size: 12px;color:#B9B9B9;padding:3px 0;padding-bottom: 0;'>
                            <p>{{ $sys('mall.orderNumber') }}：{{ item.orderCode }}</p>
                            <p>{{ $sys('mall.date') }}：{{ formatDateTime(item.createTime) }}</p>
                        </div>

                    </li>

                </ul>

            </div>

            <div class='opbtn'>
                
                <van-button @click='onSuccess'>
                    {{ $sys('mall.orderOneCancel.btnCancel') }}
                </van-button>

                <van-button class='primary' :disabled="btnLoading || !selectedOrders" @click='hanldeConfirm'>
                    {{ $sys('mall.orderOneCancel.btnConfirm') }}
                </van-button>

            </div>

        </div>
    </van-popup>

</template>

<script>
import { Popup, Icon, Button, Toast } from 'vant'
import { formatDateTime } from '@/utils'
import { apiListOrderLock, apiCancelOneClick } from '@/api/mall'
import { mapMutations } from 'vuex'
import { isMobile } from '@/utils/utils'

export default {
    components: {
        [Popup.name]: Popup,
        [Icon.name]: Icon,
        [Button.name]: Button
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            visiblePop: false,
            orderList: [],
            isMobile: isMobile(),
            loading: false,
            btnLoading: false
        }
    },
    computed: {
        selectedOrders() {
            let arr = []
            this.orderList.map(item => {
                item.isSelected && arr.push(item.orderCode)
            })
            return arr.join(',')
        }
    },
    methods: {
        ...mapMutations('user', ['SET_USER_INFO']),
        ...mapMutations('mall', ['SET_ORDER_STATUS']),
        formatDateTime,
        async getOrderData() {
            this.loading = true
            const result = await apiListOrderLock()
            this.loading = false

            this.$emit('onFinish')

            if (result.code === 0) {

                if(result.data?.length) {
                    this.orderList = result.data.map(item => ({
                        ...item,
                        isSelected: true
                    }))

                    this.visiblePop = true

                    this.$nextTick(() => {
                        if (this.$refs.listRef) {
                            this.$refs.listRef.scrollTop = 0
                        }
                    })
                }else {
                    this.onSuccess()
                }
            }else {
                this.onClose()
            }
        },
        async hanldeConfirm() {
            this.btnLoading = true
            const result = await apiCancelOneClick(this.selectedOrders)
            this.btnLoading = false

            if(result.code === 0) {
                this.onSuccess()
                this.SET_ORDER_STATUS()
            }else {
                Toast(result.message)
            }
        },
        onSuccess() {
            this.onClose()
            this.$emit('onSuccess')
        },
        onClose() {
            this.visiblePop = false
            this.$emit('update:visible', false)
        },
        handleSelected(info) {
            this.orderList = this.orderList.map(item => {
                if(item.orderCode === info.orderCode) {
                    return {
                        ...item,
                        isSelected: !item.isSelected
                    }
                }else {
                    return item
                }
            })
        }
    },
    watch: {
        visible(val) {
            val && this.getOrderData()
        }
    }
}
</script>

<style lang='scss' scoped>
.pop-order-cancel-container {
    max-height: 500px;
    min-height: 100px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background-color: #ffffff;

    ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-track {
        background-color: transparent;
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
    }

    ::-webkit-scrollbar-thumb {
        background-color: rgb(147, 147, 153, 0.5);
        -webkit-border-radius: 2em;
        -moz-border-radius: 2em;
        border-radius: 2em;
    }


    &.pc {
        width: 500px;
        height: 330px;
    }

    &.mobile {}

    .topbar {
        width: 100%;
        height: 45px;
        box-sizing: border-box;
        background-color: #EFEFEF;
        border-top: 1px solid #D4D4D4;
        line-height: 45px;
        text-align: center;
        font-size: 16px;
        padding: 0 15px;
        font-weight: bold;
        box-shadow: 0 -10px 20px;

        i {
            cursor: pointer;
            float: right;
            margin-top: 10px;
        }
    }

    .pop-order-cancel-content {
        max-height: 320px;
        width: 100%;
        padding: 0 16px;
        padding-top: 21px;
        overflow: auto;
        padding-bottom: 20px;

        .dec {
            margin-bottom: 10px;
            font-size: 14px;
            padding: 0 20px;
            text-align: center;
        }

        .list {
            width: 100%;
            min-height: 200px;

            .item {
                width: 100%;
                background-color: #F5F5F5;
                border-radius: 15px;
                box-sizing: border-box;
                padding: 12px;
                margin-bottom: 12px;
                line-height: 21px;
                position: relative;
                cursor: pointer;
                border: 1px solid transparent;

                &.selected {
                    border: 1px solid #FFA860;
                }
            }
        }

    }

    .opbtn {
        width: 100%;
        height: 60px;
        text-align: center;
        box-shadow: 0 10px 20px;

        button {
            width: 140px;
            height: 36px;
            border: none;
            border-radius: 20px;
            margin: 10px;
            font-size: 14px;
            color: #ffffff;
            background-color: #CDCDCD;
            cursor: pointer;

            &.primary {
                background-color: #FB8525;
            }
        }
    }
}

</style>