<template>
    <div class="pay_types">
        <h3 class="title">{{ $sys('mall.title.payType') }}</h3>

        <el-tree 
            v-if="payList && payList.length"
            ref="tree"
            class="pay_types_tree" 
            :data="dataList" 
            :props="defaultProps" 
            node-key="id"
            highlight-current 
            default-expand-all 
            :default-checked-keys="[this.payInfo.id]"
            @node-click="handleClick"
        ></el-tree>

        <div v-else class="no_pay_method">{{ $sys('warn.payAmountExceedLimit') }}</div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { Tree, Button } from 'element-ui'
import { getPayIconByName, getCategoryInfoByName, getPayNameByName } from '@/utils/pay'
import { logChooseMethod } from '@/utils/log'
import { comfirmBox } from '@/utils'
import { apiDeleteCardSaved } from '@/api/mall'

export default {
    components: {
        [Tree.name]: Tree,
        [Button.name]: Button
    },
    computed: {
        ...mapState('mall', ['payList', 'payInfo']),
        ...mapGetters(['payRecentlyUse']),
        dataList() {
            let listData = []

            this.payList.map(payMethod => {

                if(payMethod.payType === 'ALIPAY_GLOBAL') {

                    payMethod?.paySubTypes?.map(payTypeItem => {

                        payTypeItem = {
                            label: getPayNameByName(payTypeItem.payTypeName),
                            payMethod: payMethod.payType,
                            payType: payTypeItem.payType,
                            category: payTypeItem.category,
                            cards: payTypeItem.cards,
                            id: payTypeItem.payType
                        }

                        const categoryInfo = listData.find(item => payTypeItem.category && item.name === payTypeItem.category)

                        if(categoryInfo) {
                            listData.map(item => {
                                if(item.name === payTypeItem.category) {
                                    item.children?.push(payTypeItem)
                                }
                                return item
                            })
                        }else {
                            const categoryInfoCons = getCategoryInfoByName(payTypeItem.category)

                            if(categoryInfoCons) {
                                listData.push({
                                    ...categoryInfoCons,
                                    label: this.$sys(categoryInfoCons.label),
                                    children: [payTypeItem]
                                })
                            }else {
                                if(payTypeItem.payType === 'CARD') {
                                    const cardList = payTypeItem?.cards?.map((item) => ({
                                        cardId: item.id,
                                        label: item.cardNo,
                                        cardBrand: item.cardBrand,
                                        payMethod: payTypeItem.payMethod,
                                        payType: payTypeItem.payType,
                                        id: `${item.cardBrand}-${item.id}`
                                    })) || []

                                    listData.push({
                                        id: 'aliCard',
                                        label: this.$sys('mall.type.card'),
                                        name: 'ALICARD',
                                        children: [
                                            ...cardList,
                                            {
                                                ...payTypeItem,
                                                label: this.$sys('mall.otherCard')
                                            }
                                        ]
                                    })
                                }else {
                                    listData.push(payTypeItem)
                                }
                            }
                        }
                    })
                }


                if(payMethod.payType === 'MYCARD') {
                    const mycardInfo = {
                        id: 'mycard',
                        label: payMethod?.payTypeName,
                        payMethod: payMethod?.payType
                    }

                    listData.push(mycardInfo)
                }

            })

            // 初始化支付方式
            let isHasChooise = undefined        // 列表是否有已选择方式
            listData.map(item => {
                if(item.id === this.payInfo?.id) {
                    isHasChooise = true
                }
                item.children?.map(sub => {
                    if(sub.id === this.payInfo?.id) {
                        isHasChooise = true
                    }
                })
            })
            if(!isHasChooise) this.SET_PAY_INFO({})

            return listData
        },
        defaultProps() {
            const handleDelete = (event, info) => {
                event.stopPropagation()

                comfirmBox({
                    msg: this.$sys('mall.deleteCardTips', { cardBrand: info.cardBrand }),
                    useMobile: true
                }, async () => {
                    this.SET_ORDER_PAY_LOADING(true)
                    const result = await apiDeleteCardSaved(info.cardId)
                    this.SET_ORDER_PAY_LOADING(false)

                    if(result.code === 0) {
                        this.SET_ORDER_STATUS()
                    }
                })
                
            }

            return {
                children: 'children',
                label: (info) => {
                    const renderRecommend = () => (<span class='sign_recommend'>{this.$sys("mall.recommend")}</span>)

                    if(info.children) {
                        return info.label
                    }else {
                        if(info.cardId) {   // alipay 保存卡
                            const imgUrl = getPayIconByName(info.cardBrand)

                            return (
                                <div>
                                    <span class='icon_radio'>
                                        <i class='el-icon-check' />
                                    </span>
                                    <img src={imgUrl} height={20} style={{marginRight: '10px'}}/>
                                    <span>{info.label}</span>

                                    {info.id === this.payRecentlyUse?.id && renderRecommend()}
                                    <el-button type="text" class="btn_delete" onclick={e => handleDelete(e, info)}>
                                        { this.$sys("btn.delete") }
                                    </el-button>
                                </div>
                            )
                        }else {
                            const renderPayIcon = () => {
                                if( info.payType === 'CARD') {      // alipay card
                                    return (
                                        <div style={{float: 'right'}} >
                                            <img class='icon_pay_type' src={getPayIconByName('visa')} height={20} style={{marginRight: '10px'}}/>
                                            <img class='icon_pay_type' src={getPayIconByName('mastercard')} height={20} style={{marginRight: '10px'}}/>
                                        </div>
                                    )
                                }else {
                                    const imgUrl = getPayIconByName(info.payType) || getPayIconByName(info.payMethod)
                                    return <img class='icon_pay_type' src={imgUrl} height={20} style={{marginRight: '10px'}}/>
                                }
                            }

                            return (
                                <div>
                                    {renderPayIcon()}

                                    <span class='icon_radio'>
                                        <i class='el-icon-check' />
                                    </span>
                                    <span>{info.label}</span>

                                    {info.id === this.payRecentlyUse?.id && renderRecommend()}
                                </div>
                            )
                        }
                    }
                }
            }
        }
    },
    methods: {
        ...mapMutations('mall', ['SET_PAY_INFO', 'SET_ORDER_STATUS', 'SET_ORDER_PAY_LOADING']),
        handleClick(info) {

            if(info.children) return

            this.$refs.tree.setCheckedKeys([info.id])

            if(info.payType) {
                this.SET_PAY_INFO({
                    payType: info.payMethod?.toLocaleUpperCase(),
                    paySubType: info.payType?.toLocaleUpperCase(),
                    cardId: info.cardId,
                    id: info.id
                })

                logChooseMethod(info.payType?.toLocaleUpperCase())
            }else {
                this.SET_PAY_INFO({
                    payType: info.payMethod?.toLocaleUpperCase(),
                    id: info.id
                })

                logChooseMethod(info.payMethod?.toLocaleUpperCase())
            }
        }
    }
}
</script>

<style lang="scss"></style>