<template>
  <PopupM
    v-model="visible"
    :title="$sys('mall.title.payCart')"
    :width="isMobile ? '100%' : '1000px'"
    :position="isMobile ? 'bottom' : 'center'"
    class="pay_cart_pop"
  >

    <div class="pop_pay_cart" v-loading="mallLoading || orderPayLoading">
      <RoleInfo />

      <GoodsInfo />

      <PayTypes />

      <div class="bottom">
        <div class="total">
          <p>
            {{ $sys('mall.totalPrize') }}
            <span class="prize">{{ prizePay }}</span>
          </p>
        </div>
        <el-button
          :type="isCanPay ? 'primary' : 'info'"
          @click="() => handlePay()"
          :loading="isPaying"
          :disabled="!isCanPay"
          class="btn">
          {{ $sys('btn.payNow') }}
        </el-button>
      </div>
    </div>

  </PopupM>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { Button } from 'element-ui'
import { tips, formatCurrencyToThousand } from '@/utils'
import { aliGlobalPay, canOpenWindow, linkJumpPay } from '@/utils/pay'
import { logBeginCheckout } from '@/utils/log'
import PopupM from '@/components/Popup/M'
import RoleInfo from './RoleInfo'
import GoodsInfo from './GoodsInfo'
import PayTypes from './PayTypes'

export default {
  components: {
    [Button.name]: Button,
    PopupM,
    RoleInfo,
    GoodsInfo,
    PayTypes
  },
  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isPaying: false
    }
  },
  computed: {
    ...mapGetters(['isMobile', 'isSelectGoods', 'isCanPay']),
    ...mapState('mall', ['mallLoading', 'orderPayLoading', 'goodsInfo', 'payInfo']),
    visible: {
      get() {
        this.value && logBeginCheckout({
          item_id: this.goodsInfo.commodityCode,
          price: this.goodsInfo.commoditySellPrice,
          currency: this.goodsInfo.commodityCurrency
        })
        
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    prizePay() {
      if(!this.isSelectGoods) return ''
      
      const { commodityCurrency, prizeTotal } = this.goodsInfo
      return `${commodityCurrency} ${formatCurrencyToThousand(prizeTotal)}`
    },
    status() {
      return [this.payInfo, this.goodsInfo]
    }
  },
  methods: {
    ...mapMutations('mall', ['SET_GOODS_INFO']),
    ...mapMutations('user', ['SET_USER_INFO']),
    ...mapActions('mall', ['createOrder', 'selectGoods']),
    async handlePay() {
      if (!this.payInfo?.payType) {
        tips(this.$sys('warn.pleaseSelectPayMethod'))
        return
      }

      this.isPaying = true
      canOpenWindow(this.payInfo.paySubType || this.payInfo.payType,async (newWindow)=>{
        try {
          const result = await this.createOrder()

          this.isPaying = false

          const { appLinkUrl, normalUrl, orderCode, payPage, paymentSessionData, waitSecond, sandbox } = result

          if (paymentSessionData) {
            aliGlobalPay(paymentSessionData, {
              noCardId: !this.payInfo.cardId,
              sandbox
            })
          } else {
            if(this.payInfo?.payType === 'MYCARD') {
              if(payPage) {
                linkJumpPay(newWindow, payPage)
              }
            } else {
              if (this.isMobile) {
                linkJumpPay(newWindow,appLinkUrl || normalUrl)
              } else {
                linkJumpPay(newWindow,normalUrl)
              }
            }
          }

          const payInfo = this.payInfo
          setTimeout(() => {
            this.SET_USER_INFO({ payRecentlyUse: payInfo })
            this.selectGoods()
          }, 800)

          this.$emit('onPay', {
            time: waitSecond,
            order: orderCode,
            isPop: !!payPage
          })
        } catch (e) {
          this.isPaying = false
          newWindow && newWindow.close()
        }
      })
    },
    reset(){
      this.isPaying = false
    }
  }
}
</script>

<style lang="scss" scoped></style>