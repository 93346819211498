var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cart_goods_info" }, [
    _c("h3", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.$sys("mall.title.buy"))),
    ]),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "flex-between" }, [
        _c("div", { staticClass: "flex-start" }, [
          _c("div", { staticClass: "product_img", class: _vm.gameInfo.name }, [
            _c("img", { attrs: { src: _vm.goodsInfo.commodityIcon, alt: "" } }),
          ]),
          _c("div", [
            _c(
              "p",
              {
                staticClass: "product_name ellipse",
                attrs: { title: _vm.goodsInfo.commodityName },
              },
              [_vm._v(_vm._s(_vm.goodsInfo.commodityName))]
            ),
            _c("p", { staticClass: "product_price" }, [
              _vm._v(
                _vm._s(_vm.goodsInfo.commodityCurrency) +
                  " " +
                  _vm._s(
                    _vm.toCurrencyToThousand(_vm.goodsInfo.commoditySellPrice)
                  )
              ),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "buy_number" },
          [
            _c("el-input-number", {
              attrs: { min: 1, max: 99, precision: 0 },
              on: { change: _vm.handleNumber },
              model: {
                value: _vm.number,
                callback: function ($$v) {
                  _vm.number = $$v
                },
                expression: "number",
              },
            }),
          ],
          1
        ),
      ]),
      _c("ul", { staticClass: "list" }, [
        _c("li", { staticClass: "flex-between" }, [
          _c("span", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.$sys("mall.goodsPrizeOriginal"))),
          ]),
          _c("span", { staticClass: "value" }, [
            _vm._v(
              _vm._s(_vm.goodsInfo.commodityCurrency) +
                " " +
                _vm._s(
                  _vm.toCurrencyToThousand(_vm.goodsInfo.commodityOriginalPrice)
                )
            ),
          ]),
        ]),
        _c("li", { staticClass: "flex-between" }, [
          _c("span", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.$sys("mall.goodsAdd"))),
          ]),
          _c(
            "span",
            {
              staticClass: "value ellipse",
              attrs: { title: _vm.goodsInfo.preferentialExplain },
            },
            [_vm._v(_vm._s(_vm.goodsInfo.preferentialExplain || "-"))]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }