<template>
    <div class="cart_role_info" v-if="isRole">
        <div>
            <div class="info flex-start">
                <div class="user_default_avatar">
                  <img :src="require('images/icon/icon-avatar.png')" >
                </div>
                <div>
                    <p class="role_name">{{ userInfo.gameRoleName }}</p>
                    <p class="role_other">
                        <span>{{ $sys('account.server') }}：{{ userInfo.gameZoneName }}</span>
                        <span>{{ $sys('account.roleID') }}：{{ userInfo.gameRoleCode }}</span>
                    </p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters(['isRole']),
        ...mapState('user', ['userInfo'])
    }
}
</script>

<style lang="scss" scoped>

</style>